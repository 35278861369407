import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39f29823"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "active-challenges-widget" }
const _hoisted_2 = {
  key: 0,
  class: "active-challenges-widget__list"
}
const _hoisted_3 = {
  key: 1,
  class: "active-challenges-widget__block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_ActiveChallenge = _resolveComponent("ActiveChallenge")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_LinkTemplate = _resolveComponent("LinkTemplate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      open: _ctx.state.premiumModalShow,
      "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.premiumModalShow) = $event)),
      centered: true,
      closable: false,
      title: "Premium!",
      onCancel: _ctx.handlePremiumModalClose
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          onClick: _ctx.handlePremiumModalGoTo
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Получить Premium ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" Для того, чтобы поставить Цель дня оформи Premium! ")
      ]),
      _: 1
    }, 8, ["open", "onCancel"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        block: "",
        onClick: _ctx.goToCrateOneDayChallenge
      }, {
        default: _withCtx(() => [
          _createTextVNode(" ЦЕЛЬ ДНЯ ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        block: "",
        onClick: _ctx.goToCrateChallenge
      }, {
        default: _withCtx(() => [
          _createTextVNode(" ПОСТАВИТЬ ЦЕЛЛЕНЖ ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.challengeList.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.challengeList, (challenge, index) => {
              return (_openBlock(), _createBlock(_component_ActiveChallenge, {
                key: `activeChallenge${index}`,
                challenge: challenge,
                user: _ctx.user,
                onArchive: _ctx.handleArchive
              }, null, 8, ["challenge", "user", "onArchive"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_a_typography_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" Поставь первую цель-челлендж. ")
              ]),
              _: 1
            }),
            _createVNode(_component_LinkTemplate, {
              "text-before": "см. ",
              "link-text": "Важный раздел",
              "text-after": ", раздел:",
              onLinkClick: _ctx.handleImportantSectionLinkClick
            }, null, 8, ["onLinkClick"]),
            _createVNode(_component_a_typography_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" Поставить цель. ")
              ]),
              _: 1
            })
          ]))
    ])
  ], 64))
}