import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "card__text" }
const _hoisted_3 = {
  key: 0,
  class: "card__list"
}
const _hoisted_4 = {
  key: 1,
  class: "card__input"
}
const _hoisted_5 = {
  key: 2,
  class: "card__input"
}
const _hoisted_6 = {
  key: 0,
  class: "card__button-group card__button-group_number-of-reports"
}
const _hoisted_7 = {
  key: 1,
  class: "card__button-group card__button-group_report-type"
}
const _hoisted_8 = { class: "card__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PromoCodeWidget = _resolveComponent("PromoCodeWidget")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_ctx.state.isCodeTemplate)
    ? (_openBlock(), _createBlock(_component_PromoCodeWidget, {
        key: 0,
        onSubmitCode: _ctx.onSubmitCode,
        onRejectCode: _ctx.onRejectCode
      }, null, 8, ["onSubmitCode", "onRejectCode"]))
    : (_openBlock(), _createBlock(_component_a_card, {
        key: 1,
        hoverable: "",
        class: "card"
      }, {
        cover: _withCtx(() => [
          (_ctx.activeOption.image)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                alt: "example",
                src: _ctx.activeOption.image
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeOption.textLines, (text, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: `textLine${index}`
              }, [
                _createElementVNode("p", null, _toDisplayString(text.text), 1),
                (text.list)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text.list, (listItem, listIndex) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: `listItem${index}${listIndex}`
                        }, _toDisplayString(listItem), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]),
          (_ctx.activeOption.field && _ctx.activeOption.valueType === 'input')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_a_input, {
                  maxLength: _ctx.activeOption.maxLength,
                  value: _ctx.state.values[_ctx.activeOption.field],
                  disabled: _ctx.state.disabled[_ctx.activeOption.field],
                  onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInputChange($event, _ctx.activeOption.field)))
                }, null, 8, ["maxLength", "value", "disabled"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeOption.field && _ctx.activeOption.valueType === 'buttonGroup')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.activeOption.field === 'numberOfReports')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMaxNumberOfReportsValue(), (index) => {
                        return (_openBlock(), _createBlock(_component_a_button, {
                          key: `number-of-reports-${index}`,
                          type: _ctx.getIsActiveButton(`${index}`, _ctx.activeOption.field),
                          disabled: _ctx.state.disabled[_ctx.activeOption.field],
                          size: "small",
                          onClick: ($event: any) => (_ctx.handleButtonGroupClick(`${index}`, _ctx.activeOption.field))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(index), 1)
                          ]),
                          _: 2
                        }, 1032, ["type", "disabled", "onClick"]))
                      }), 128))
                    ]))
                  : (_ctx.activeOption.field === 'reportType')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.list, (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_button, {
                            key: `number-of-reports-${index}`,
                            type: _ctx.getIsActiveButton(item.value, _ctx.activeOption.field),
                            disabled: _ctx.state.disabled[_ctx.activeOption.field],
                            size: "small",
                            onClick: ($event: any) => (_ctx.handleButtonGroupClick(item.value, _ctx.activeOption.field))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["type", "disabled", "onClick"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (!_ctx.showApplyButton && _ctx.activeOption.buttonText)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  block: "",
                  disabled: _ctx.isForwardDisabled,
                  onClick: _ctx.handleButtonClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.activeOption.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.showApplyButton)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  type: "primary",
                  block: "",
                  disabled: _ctx.isForwardDisabled,
                  onClick: _ctx.handleApplyButton
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Готово ")
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
}